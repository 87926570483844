'use client';

import { createContext, useContext, useEffect, PropsWithChildren } from 'react';
import { initReactI18next, UseTranslationOptions, useTranslation as useTranslationOrg } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LanguageDetectorModule } from 'i18next';
import initializeI18n from './initializer';
import { acceptedLanguages, fallbackLng } from './settings';

const i18nInstance = initializeI18n(fallbackLng)({
  extend: [initReactI18next, LanguageDetector as unknown as LanguageDetectorModule],
  options: {
    lng: undefined,
    detection: {
      order: ['cookie', 'navigator'],
    },
    preload: typeof window !== 'undefined' ? acceptedLanguages : [],
  },
});
export { i18nInstance };

const I18nContext = createContext(fallbackLng);

export function I18nProvider({ children = null, lang }: PropsWithChildren<{ lang: string }>) {
  useEffect(() => {
    initializeI18n(lang);
  }, [lang]);

  return (
    <I18nContext.Provider value={lang}>
      {children}
    </I18nContext.Provider>
  );
}

export function useTranslation(ns: string | string[] = 'translation', options: UseTranslationOptions<undefined> = {}) {
  const lang = useContext(I18nContext);
  const { t, i18n } = useTranslationOrg(ns, options);
  if (lang !== i18n.resolvedLanguage) {
    i18n.changeLanguage(lang);
  }

  return { t, i18n };
}
