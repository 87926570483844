import { DatePicker as AntDatePicker, DatePickerProps as AntDatePickerProps } from 'antd';
import React from 'react';
import {
  Control,
  Controller,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { RangePickerProps } from 'antd/es/date-picker';
import textInputStyles from './textInput.module.scss';

const { RangePicker: AntRangePicker } = AntDatePicker;

interface OtmowDatePickerProps {
  label?: string,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string
}
type DatePickerProps = OtmowDatePickerProps & AntDatePickerProps;

const DatePicker: React.FC<DatePickerProps> = ({
  label = '', formRegister, formControl, className = '', ...props
}) => (
  <div className={textInputStyles.textInput}>
    <span className={textInputStyles.label}>{label}</span>
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({
        field,
      }) => (
        <AntDatePicker className={className} {...field} {...props} />
      )}
    />
  </div>
);

const RangePicker: React.FC<OtmowDatePickerProps & RangePickerProps> = ({ label = '', formRegister, formControl, ...props }) => (
  <div className={textInputStyles.textInput}>
    <span className={textInputStyles.label}>{label}</span>
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({
        field,
      }) => (
        <AntRangePicker {...field} {...props} />
      )}
    />
  </div>
);

export { DatePicker, RangePicker };
