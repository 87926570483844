import { createInstance, InitOptions, LanguageDetectorModule, ThirdPartyModule } from 'i18next';
import I18NextChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { acceptedLanguages, fallbackLng } from './settings';

export default function initializeI18n(lng: string) {
  const i18nInstance = createInstance();
  const baseInstance = i18nInstance
    .use(I18NextChainedBackend);

  type InitProps = {
    extend: Array<ThirdPartyModule | LanguageDetectorModule>;
    options: InitOptions;
  }
  async function init({ extend = [], options = {} }: InitProps) {
    const mountedInstance = extend.reduce((acc, module) => acc.use(module), baseInstance);
    await mountedInstance.init({
      supportedLngs: acceptedLanguages,
      fallbackLng,
      lng,
      fallbackNS: 'translation',
      defaultNS: 'translation',
      ns: 'translation',
      nsSeparator: false,
      backend: {
        backends: [
          resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)),
          LocizeBackend,
        ],
        backendOptions: [
          {},
          {
            projectId: 'acb24f95-aa97-4ed5-af9a-04654c35b2bf',
            referenceLng: 'en-US',
            apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
            saveMissing: process.env.REACT_APP_LOCIZE_SAVE_MISSING === 'true',
          },
        ],
      },
      ...options,
    });
    return mountedInstance;
  }

  return init;
}
